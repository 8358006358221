<template>
  <!--
    Used to manage the contacts
  -->
  <div class="contactsList">
    <LoadingPlaceholder v-if="loading" />
    
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(contacts)"
        :columns="kgm_responsiveColumns()"
        :filterable="true"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(contacts)"
        :sortable="{
          allowUnsort: kgm_allowUnsort,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="linkTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <font-awesome-icon
              icon="external-link-alt"
              @click="createUrl(props.dataItem.id)"
            />
          </td>
        </template>
        <div
          slot="textFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <input
            ref="textFilterInput"
            :value="props.value"
            type="text"
            class="form-control mr-2 rounded-right"
            placeholder="please enter a value"
            @input="(event) => {methods.change({operator: 'contains', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
          <div
            v-if="props.value"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetInput('textFilterInput', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="metisLoginCell"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div style="display: flex; justify-content: center;">
              <template>
                <font-awesome-icon
                  v-if="props.dataItem.keycloakRoles && (props.dataItem.keycloakRoles.includes('scope_staff') ||
                    props.dataItem.keycloakRoles.includes('scope_customer'))"
                  v-tooltip="props.dataItem.keycloakRoles.toString()"
                  icon="door-open"
                  size="2x"
                />
                <font-awesome-icon
                  v-else-if="props.dataItem.isInKeycloak && (!props.dataItem.keycloakRoles || (props.dataItem.keycloakRoles &&
                    !(props.dataItem.keycloakRoles.includes('scope_staff') ||
                      props.dataItem.keycloakRoles.includes('scope_customer'))))"
                  v-tooltip="props.dataItem.keycloakRoles ? props.dataItem.keycloakRoles.toString() : 'no roles'"
                  icon="door-closed"
                  size="2x"
                  style="color: blue;"
                />
                <font-awesome-icon
                  v-else-if="!props.dataItem.isInKeycloak"
                  icon="door-closed"
                  size="2x"
                  style="color: black;"
                />
              </template>
            </div>
          </td>
        </template>
        <div
          slot="notificationFilter"
          slot-scope="{props, methods}"
          class="input-group"
        >
          <select
            ref="notificationSelect"
            :key="`notificationSelect-${props.value}`"
            :value="props.value"
            class="form-control mr-2 rounded-right alt-pointer"
            @change="(event) => {methods.change({operator: 'eq', field: props.field, value: event.target.value, syntheticEvent: event});}"
          >
            <option
              value=""
              disabled
            >
              {{ $t('pleaseSelectOne') }}
            </option>
            <option
              v-for="(status, key) in notificationMedia"
              :key="`notification-statusListFilterOption-${key}`"
              :selected="kgm_selectDefaultOption(props, status, 'notificationSelect')"
            >
              {{ status }}
            </option>
          </select>
          <div
            v-if="$refs != null && $refs.notificationSelect != null && ($refs.notificationSelect.value != null && $refs.notificationSelect.value.length != 0)"
            class="input-group-append"
          >
            <button
              class="btn btn-light border rounded"
              @click="kgm_resetSelect('notificationSelect', (event) => {methods.change({operator: '', field: '', value: '', syntheticEvent: event});})"
            >
              <font-awesome-icon
                class="gray"
                icon="times"
              />
            </button>
          </div>
        </div>
        <template
          slot="checkboxTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            >
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div
              v-if="!isCall"
              class="btn-group"
            >
              <button
                v-if="!(props.dataItem.roleAllowMultiple && authenticationHasRole('scope_customer'))"
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  icon="edit"
                />
              </button>
              <button
                class="btn btn-secondary btn-sm"
                @click="openConfirmModal(props.dataItem)"
              >
                <font-awesome-icon
                  class="gray"
                  icon="trash"
                />
              </button>
              <button
                class="btn btn-primary btn-sm"
                @click="openInstallationsModal(props.dataItem)"
              >
                <span>Installations</span>
              </button>
            </div>
            <div
              v-if="isCall && authenticationHasRole('scope_staff')"
              class="btn-group"
            >
              <button
                class="btn btn-primary btn-sm"
                @click="openNotesModal(props.dataItem)"
              >
                <span>Notes</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <ContactsAdd
          v-if="addingElement"
          @reload="reloadContacts"
        />
        <ContactsEdit
          v-else
          :contact="contact"
          @reload="reloadContacts"
        />
      </Sidebar>
    </template>

    <SweetModal
      ref="confirmDelete"
      :title="$t('introOutroListComp.deleteElement')"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p v-if="contact">
        {{ $t('sureToDelete', {'0' : contact.fullname}) }}
      </p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmDelete.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-danger float-right mb-3"
        @click="removeContact()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="trash"
        />{{ $t('delete') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="notes"
      title="Notes"
      blocking
      class="overflowHidden"
    >
      <LoadingPlaceholder v-if="modalLoading" />
      <template v-else>
        <div
          style="overflow: auto; max-height: 450px;"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  ID
                </th>
                <th scope="col">
                  Name
                </th>
                <th
                  scope="col"
                >
                  {{ $t('installationDetailOperationView.note') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in installations"
                :key="`id-listItem-${ index }`"
              >
                <td>
                  <router-link
                    :to="{ path: '/installation/' + item.installationId + '/operation/q/' + contact.fullname }"
                    active-class="active"
                    tag="a"
                    target="_blank"
                  >
                    <span>{{ item.installationId }}</span>
                  </router-link>
                </td>
                <td>
                  <span>
                    {{ item.installationName }}
                  </span>
                </td>
                <input
                  v-model="item.note"
                  v-focus
                  :placeholder="$t('installationDetailOperationView.insertNote')"
                  :class="['form-control m-input m-input--air']"
                >
              </tr>
            </tbody>
          </table>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-primary mr-3"
        :disabled="disabledAddNote"
        @click="createNotes()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="plus"
        />{{ $t('installationDetailOperationView.addNote') }}
      </button>
      <button
        slot="button"
        class="btn btn-secondary float-right"
        @click="$refs.installations.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
    <SweetModal
      ref="installations"
      :title="$t('installations')"
      blocking
      class="overflowHidden"
    >
      <LoadingPlaceholder v-if="modalLoading" />
      <template v-else>
        <div
          style="overflow: auto; max-height: 450px;"
        >
          <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  ID
                </th>
                <th scope="col">
                  Name
                </th>
                <th scope="col">
                  Role
                </th>
                <th scope="col">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in installations"
                :key="`id-listItem-${ index }`"
              >
                <td>
                  <router-link
                    :to="{ path: '/installation/' + item.installationId + '/operation' }"
                    active-class="active"
                    tag="a"
                    target="_blank"
                  >
                    <span>{{ item.installationId }}</span>
                  </router-link>
                </td>
                <td>
                  <span>
                    {{ item.installationName }}
                  </span>
                </td>
                <td>
                  <select
                    v-model="item.roleId"
                    class="form-control"
                  >
                    <option
                      v-for="(role, index) in contactRoles"
                      :key="`rol${ index }`"
                      :value="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </td>
                <td>
                  <button
                    class="btn btn-danger btn-sm"
                    @click="addToRemoveList(item.id)"
                  >
                    <font-awesome-icon
                      icon="trash"
                    />
                  </button>
                </td>
              </tr>
              <tr v-if="addingButton">
                <td colspan="2">
                  <Multiselect
                    v-if="installationsToAdd"
                    id="installation"
                    v-model="selectedInstallation"
                    tag-placeholder="Not found"
                    placeholder="Select a installation"
                    :options="searchedInstallations"
                    select-label=""
                    :multiple="false"
                    :taggable="true"
                    track-by="id"
                    label="name"
                    :hide-selected="false"
                    :searchable="true"
                    :close-on-select="true"
                    :internal-search="false"
                    class="mb-4"
                    @search-change="searchInstallation"
                  />
                </td>
                <td>
                  <select
                    v-model="selectedRole"
                    class="form-control"
                  >
                    <option
                      v-for="(role, index) in contactRoles"
                      :key="`rol1${ index }`"
                      :value="role.id"
                    >
                      {{ role.name }}
                    </option>
                  </select>
                </td>
                <td>
                  <button
                    class="btn btn-success btn-sm"
                    :disabled="!selectedRole || !selectedInstallation"
                    @click="addToAddList()"
                  >
                    <font-awesome-icon
                      icon="save"
                    />
                  </button>
                  <button
                    class="btn btn-danger btn-sm ml-2"
                    @click="cancelAdding()"
                  >
                    <font-awesome-icon
                      icon="ban"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <button
            v-if="!addingButton"
            slot="button"
            class="btn btn-sm btn-primary float-left mt-2 mb-2"
            @click="setAddingButton()"
          >
            <font-awesome-icon
              class="mr-1"
              icon="plus"
            />
            <span>
              {{ $t('add') }} Installation
            </span>
          </button>
        </div>
      </template>
      <button
        slot="button"
        class="btn btn-success mr-3"
        :disabled="addingButton || !((installations && installations.length > 0) || (installationsToRemove && installationsToRemove.length > 0))"
        @click="saveContactInstallations()"
      >
        {{ $t('save') }}
      </button>
      <button
        slot="button"
        class="btn btn-secondary float-right"
        @click="$refs.installations.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';
import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import Sidebar from '@/components/Base/Sidebar.vue';
import ContactsEdit from '@/components/Contacts/ContactsEdit.vue';
import ContactsAdd from '@/components/Contacts/ContactsAdd.vue';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import historyAdapter from '@/mixins/historyAdapter.js';

export default {
  name: "ContactsList",
  components: {
    SweetModal,
    Sidebar,
    ContactsEdit,
    ContactsAdd,
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin,
    authenticationMixin,
    historyAdapter
  ],
  data () {
    return {
      loading: false,
      modalLoading: false,
      contacts: null,
      contact: null,
      showSidebar: false,
      addingElement: false,
      installations: null,
      addingButton: false,
      installationsToAdd: null,
      searchedInstallations: null,
      selectedInstallation: null,
      selectedRole: null,
      contactRoles: null,
      isCall: false,
      installationsToRemove: [],
      notificationMedia: ['SMS', 'Email', 'SMS and Email', 'Voice'],
      languages: ['German', 'French', 'Italian', 'Spanish', 'English'],
      kgm_filter: {
        logic: "and",
        filters: [
          {
            field: "phone",
            operator: "contains",
            value: this.$route.params.phone != null ? this.$route.params.phone : ""
          }
        ]
      },
      kgm_columns: [
        {
          filterable: false,
          width: '40px',
          cell: 'linkTemplate',
          hidden: this.authenticationHasRole('scope_customer')
        },
        {
          field: 'fullname',
          filterable: true,
          filter: 'text',
          title: 'Name',
          filterCell: "textFilter"
        },
        {
          field: 'email',
          filterable: true,
          filter: 'text',
          title: 'Email',
          filterCell: "textFilter"
        },
        {
          field: 'phone',
          filterable: true,
          filter: 'text',
          title: this.$t('phone'),
          filterCell: "textFilter"
        },
        {
          field: 'customerAbbrevation',
          filterable: true,
          filter: 'text',
          title: this.$t('customer'),
          filterCell: "textFilter"
        },
        {
          field: 'language',
          filterable: true,
          filter: 'text',
          title: this.$t('language'),
          filterCell: "textFilter"
        },
        {
          field: 'notificationMedia',
          filterable: true,
          filter: 'text',
          title: this.$t('contactNotificationMedia'),
          filterCell: "notificationFilter"
        },
        {
          field: 'metisLogin',
          filterable: false,
          title: `Metis Login`,
          cell: 'metisLoginCell',
          hideOn: ['xsDown']
        },
        {
          field: 'hasRoleError',
          filterable: true,
          title: `Has Role Error`,
          filter: 'boolean',
          hidden: this.authenticationHasRole('scope_customer'),
          hideOn: ['xsDown'],
          width: '100px',
          cell: 'checkboxTemplate'
        },
        {
          field: 'hasCustomerError',
          filterable: true,
          title: `Has Customer Error`,
          filter: 'boolean',
          hidden: this.authenticationHasRole('scope_customer'),
          hideOn: ['xsDown'],
          width: '100px',
          cell: 'checkboxTemplate'
        },
        {
          filterable: false,
          title: this.$t('options'),
          cell: 'optionsTemplate'
        }
      ]
    }
  },
  computed: {
     disabledAddNote () {
       return this.installations && !this.installations.filter(x => x.note != null).map(x => x.note.length).some((e) => e > 0);
     }
  },
  metaInfo () {
    return {
      title: this.$t('menu.contacts')
    }
  },
  created () {
    if(this.$route.params.phone) {
      this.isCall = true;
      var text = "Search for " + this.$route.params.phone + " requested at "+ this.dateTime_dateTimeSeconds(this.moment());
      this.$emit('showCall', text);
    }
    this.getContactRoles();
    this.getContacts();
  },
  methods: {
    createUrl (id) {
      var url = `${window.location.origin}/contacts?id=${id}`;
      navigator.clipboard.writeText(url);
      this.$snotify.success("The Contact URL has been copied to your clipboard.");
    },
    searchInstallation (query) {
      this.searchedInstallations = this.installationsToAdd.filter(x => 
      (x.id && x.id.toLowerCase() == query.toLowerCase()) ||
      (x.name && x.name.toLowerCase().includes(query.toLowerCase()))
      )
    },
    addToRemoveList (id) {
      if ((typeof id === 'string' || id instanceof String) && id.startsWith('hal')) {
        this.installations = this.installations.filter(x => id != x.id)
      }
      else {
        this.installationsToRemove.push(id)
        this.installations = this.installations.filter(x => !this.installationsToRemove.some(t => t == x.id))
      }
    },
    getName (first, last) {      
      if(first != null & last != null) {
        return first + ' ' + last;
      }
      else if(first != null && !last) {
        return first;
      }
      else if(!first && last != null) {
        return last;
      }

      return '';
    },
    getNotificationMedia (e) {
      if (!e) {
        return null
      }
      if (e.includes('sms') && e.includes('email')) {
        return 'SMS and Email'
      }
      if (e.includes('sms')) {
        return 'SMS'
      }
      if (e.includes('email')) {
        return 'Email'
      }
      if (e.includes('voice')) {
        return 'Voice'
      }
      return null
    },
    openInstallationsModal (contact) {
      this.addingButton = false;
      this.installationsToRemove = [];
      this.contact = contact;
      this.getInstallationsToAdd();
      this.getContactInstallations();
      this.$refs.installations.open();
    },
    openNotesModal (contact) {
      this.contact = contact;
      this.getContactInstallations();
      this.$refs.notes.open();
    },
    //#region Sidebar
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.showSidebar = true;
    },
    showSidebarEdit (contact) {
      this.contact = contact;
      this.addingElement = false;
      this.showSidebar = true;
    },
    //#endregion
    setAddingButton () {
      this.selectedRole = this.contactRoles[0].id;
      this.addingButton = true;      
    },
    cancelAdding () {
      this.addingButton = false;
      this.selectedRole = null;
      this.selectedInstallation = null;
    },
    addToAddList () {
      this.installations.push({
        id: `hal${this.selectedInstallation.id}${this.selectedRole}`,
        contactId: this.contact.id,
        installationId: this.selectedInstallation.id,
        installationName: this.selectedInstallation.name,
        roleId: this.selectedRole})

      this.addingButton = false;
      this.selectedRole = null;
      this.selectedInstallation = null;
    },

    //#region API-calls
    reloadContacts () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getContacts();
    },
    openConfirmModal (contact) {
      this.contact = contact;
      this.$refs.confirmDelete.open();
    },
    removeContact () {
      this.axios.delete(`/Contact/Contact?id=${this.contact.id}`, )
        .then(() => {
          this.$snotify.success(this.$t('contacts.contactDeletedSuccessfully'));
          this.reloadContacts();
        })
        .finally(() => {
          this.$refs.confirmDelete.close();
        });
    },
    createNotes () {
      this.modalLoading = true;
      this.installations.forEach(e => {
        if(e.note) {
          let text = "Customer: " + this.contact.fullname + " \n ------------------ \n Note: " + e.note;
          this.sendHistoryComment(e.installationId, text, "Customer Contacted Us");
        }
      });
      this.modalLoading = false;
      this.$refs.notes.close()
    },
    getContacts () {
      this.loading = true;
      this.axios.get('/Contact/Contacts')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contacts = response.data;
          this.contacts.forEach(e => {
            e.fullname = this.getName(e.firstname, e.surname);
            e.phone = e.phone != null ? e.phone.replaceAll(" ", "") : null;
            e.language = e.language != null ? e.language.join(', ') : null;
            e.notificationMedia = this.getNotificationMedia(e.notificationMedia);
          });

          if (this.$route.query.id) {
            let contact = this.contacts.find(x => x.id+'' === this.$route.query.id)
            this.showSidebarEdit(contact)
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getContactRoles () {
      this.axios.get('/Contact/ContactRoles')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contactRoles = response.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        })
    },
    getInstallationsToAdd () {
      this.axios.get(`/Contact/InstallationsToAdd?contactId=${this.contact.id}`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.installationsToAdd = response.data;
          this.searchedInstallations = response.data;
        })
    },
    getContactInstallations () {
      this.modalLoading = true;
      this.installations = null;
      this.axios.get(`/Contact/ContactInstallations?contactId=${this.contact.id}`)
        .then((response) => {
          if (response.data) {
            this.installations = response.data;
          }
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    saveContactInstallations () {
      this.modalLoading = true;
      this.installations.forEach(e => {
        if ((typeof e.id === 'string' || e.id instanceof String)) {
          e.id = -1
        }
      });
      const obj = {
        installations: this.installations.filter(x => x.id !== -1),
        installationsToRemove: this.installationsToRemove,
        installationsToAdd: this.installations.filter(x => x.id === -1)
      }
      this.installations = null;
      this.installationsToRemove = null;
      this.axios.put(`/Contact/ContactInstallations`, obj)
        .finally(() => {
          this.modalLoading = false;
          this.$refs.installations.close()
        });
    },
    //#endregion
  }
}
</script>
<style>
.contactsList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>