<template>
  <!--
    Used to create a contact
  -->
  <div class="contactAdd">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ $t('contact') }}
        </h4>
        <hr class="m-0 mb-3">
        <label>Firstname</label>
        <input
          v-model="contact.firstname"
          v-focus
          :class="['form-control', { 'is-invalid': $validator.errors.has('Firstname') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Surname</label>
        <input
          v-model="contact.surname"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Surname') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>Email</label>
        <input
          v-model="contact.email"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Email') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('phone') }}</label>
        <input
          v-model="contact.phone"
          :class="['form-control', { 'is-invalid': $validator.errors.has('Phone') }]"
          type="text"
        >
        <template v-if="installationId">
          <LoadingPlaceholder v-if="loading" />
          <template v-else>
            <hr class="m-0 mb-3">
            <label>Role</label>
            <Multiselect
              id="role"
              v-model="selectedRole"
              tag-placeholder="Not found"
              placeholder="Select a Role"
              :options="contactRoles"
              select-label=""
              :multiple="false"
              :taggable="true"
              track-by="id"
              label="name"
              :hide-selected="false"
              :searchable="true"
              :close-on-select="true"
              class="mb-4"
            />
          </template>
        </template>
        <template v-else>
          <LoadingPlaceholder v-if="loading" />
          <template v-else>
            <hr class="m-0 mb-3">
            <label>{{ $t('customer') }}</label>
            <Multiselect
              id="customer"
              v-model="selectedCustomer"
              tag-placeholder="Not found"
              placeholder="Select a Customer"
              :options="customers"
              select-label=""
              :multiple="false"
              :taggable="true"
              track-by="id"
              label="abbrevation"
              :hide-selected="false"
              :searchable="true"
              :close-on-select="true"
              class="mb-4"
            />
          </template>
        </template>
        <label>{{ $t('contactNotificationMedia') }}</label>
        <select
          v-model="contact.notificationMedia"
          class="form-control"
        >
          <option
            v-for="(nmedia, index) in notificationMedia"
            :key="`nmedia${ index }`"
            :value="nmedia"
          >
            {{ nmedia }}
          </option>
        </select>
        <label>{{ $t('language') }}</label>
        <Multiselect
          id="languages"
          v-model="contact.language"
          tag-placeholder="Not found"
          placeholder="Select Languages"
          :options="languages"
          select-label=""
          :multiple="true"
          :taggable="true"
          :hide-selected="true"
          :searchable="false"
          :close-on-select="true"
          class="mb-4"
        />
      </div>
      <button 
        class="btn btn-primary float-right"
        :disabled="!installationId && !selectedCustomer"
        @click.prevent="addContact()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="check"
        />{{ $t('add') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "ContactAdd",
  components: {
    Multiselect: () => import('vue-multiselect'),
  },
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      contact: {
        language: 'German',
        notificationMedia: 'Email'
      },
      notificationMedia: [ null, 'SMS', 'Email', 'SMS and Email', 'Voice'],
      languages: ['German', 'French', 'Italian', 'Spanish', 'English'],
      customers: null,
      contactRoles: null,
      loading: false,
      selectedRole: null,
      selectedCustomer: null
    }
  },
  created () {
    if (this.installationId) {
      this.getContactRoles();
    }
    else {
      this.getCustomers();
    }
  },
  methods: {
    getNotificationMedia (e) {
      if (!e) {
        return null
      }
      if (e === 'SMS and Email') {
        return ['sms','email']
      }
      if (e === 'SMS') {
        return ['sms']
      }
      if (e === 'Email') {
        return ['email']
      }
      if (e === 'Voice') {
        return ['voice']
      }
      return null
    },
    async addContact () {
      let media
      if(this.contact.notificationMedia) {
        media = this.contact.notificationMedia
        this.contact.notificationMedia = this.getNotificationMedia(this.contact.notificationMedia)
      }
      if(this.selectedRole) {
        this.contact.roleId = this.selectedRole.id;
      }

      if(this.selectedCustomer) {
        this.contact.customerId = this.selectedCustomer.id;
      }

      if (typeof this.contact.language === 'string' || this.contact.language instanceof String) {
        this.contact.language = this.contact.language.split(',')
      }

      let url = `/Contact/Contact`
      if (this.installationId) {
        url += `?installationId=${this.installationId}`
      }
      await this.axios.post(url, this.contact)
        .then(() => { 
          this.$snotify.success(this.$t('contacts.contactAddedSuccessfully'));       
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.contact.notificationMedia = media;
          this.error_clear();
          this.error_validate(error);
        });
    },
    getCustomers () {
      this.loading = true;
      this.axios.get('/Contact/Customers')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.customers = response.data.sort((a, b) => {
            return a.abbrevation.localeCompare(b.abbrevation);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getContactRoles () {
      this.loading = true;
      this.axios.get('/Contact/ContactRoles')
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.contactRoles = response.data.sort((a, b) => {
            return a.name.localeCompare(b.name);
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  }
}
</script>
<style scoped>
.contactAdd .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>